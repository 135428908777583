import LottiePlayer from "./components/LottiePlayer";
import { debounce } from "lodash";
import SectionDoor from "./components/SectionDoor";
import BuildingBlocks from "./components/BuildingBlocks";
import Aaa from "./components/Aaa";
import AxesSpecimen from "./components/AxesSpecimen";
import Nav from "./components/Nav";
import CharSet from "./components/CharSet";
import Typetester from "./components/Typetester";

const onResize = debounce(() => {
  requestAnimationFrame(() => {
    document.documentElement.style.setProperty(
      "--visible-viewport-height",
      `${window.innerHeight}px`
    );
  });
}, 111);

const navEls = [...document.querySelectorAll("[data-component='nav']")];
navEls.forEach((el) => Nav(el));

const lottieEls = [
  ...document.querySelectorAll("[data-component*='lottie-player']"),
];
lottieEls.forEach((el) => LottiePlayer(el));

const sectionDoorEls = [
  ...document.querySelectorAll("[data-component='section-door']"),
];
sectionDoorEls.forEach((el) => SectionDoor(el));

const buildingBlocksEls = [
  ...document.querySelectorAll("[data-component='bb']"),
];
buildingBlocksEls.forEach((el) => BuildingBlocks(el));

const aaaEls = [...document.querySelectorAll("[data-component='aaa']")];
aaaEls.forEach((el) => Aaa(el));

const axesSpecimenEls = [
  ...document.querySelectorAll("[data-component='axes-specimen']"),
];
axesSpecimenEls.forEach((el) => AxesSpecimen(el));

const charSetEls = [
  ...document.querySelectorAll("[data-component='char-set']"),
];
charSetEls.forEach((el) => CharSet(el));

const ttToggles = [
  ...document.querySelectorAll("[data-component='tt__toggle']"),
];
ttToggles.forEach((el) =>
  el.addEventListener("click", () =>
    document.body.classList.toggle("tt-active")
  )
);

const typetesterEls = [
  ...document.querySelectorAll("[data-component='typetester']"),
];
typetesterEls.forEach((el) => Typetester(el));

onResize();
onResize.flush();
window.addEventListener("resize", onResize);
