import { getScrollTop, lerp, quantize } from "../util/lib";
import { clamp } from "lodash";
import { easeOutCubic } from "js-easing-functions";
import useOrientationOrMousePosition from "../util/useOrientationOrMousePosition";
import anime from "animejs";

export const convertToRange = (value, srcRange, dstRange) => {
  if (value < srcRange[0]) return dstRange[0];
  if (value > srcRange[1]) return dstRange[1];

  const srcMax = srcRange[1] - srcRange[0];
  const dstMax = dstRange[1] - dstRange[0];
  const adjValue = value - srcRange[0];

  return (adjValue * dstMax) / srcMax + dstRange[0];
};

const BuildingBlocks = (el) => {
  // return;
  const blocks = [
    ...document.querySelectorAll("[data-component='bb__block']"),
  ] as HTMLDivElement[];
  let raf;
  let min, max;
  const autoplayAnimation = matchMedia("(pointer:coarse)").matches;
  // const autoplayAnimation = false;
  const targets = {
    x: 0.5,
    y: 0.5,
  };
  let pc = 0;

  const setBlockSizes = () => {
    const aspect = parseFloat(el.dataset.aspectRatio);
    const width = parseFloat(el.dataset.width);
    let baseWidth = window.innerWidth * width;
    let baseHeight = baseWidth * aspect;

    // if (baseHeight > window.innerHeight * 0.8) {
    //   baseHeight = window.innerHeight * 0.8;
    //   baseWidth = (baseHeight * 1) / aspect;
    // }

    const offsetLeft = (window.innerWidth - baseWidth) / 2;
    const offsetTop = (window.innerHeight - baseHeight) / 2 - 25;

    blocks.forEach((block) => {
      const width = baseWidth / parseInt(block.dataset.rowLength || "1");
      const left =
        width * parseInt(block.dataset.columnIndex || "0") + offsetLeft;
      const top =
        (baseHeight / 3) * parseInt(block.dataset.rowIndex || "0") + offsetTop;
      block.style.width = `${width}px`;
      block.style.height = `${baseHeight / 3}px`;
      block.style.left = `${left}px`;
      block.style.top = `${top}px`;
    });
  };

  const setTypeVariationSettings = (pcX, pcY) => {
    if (el.dataset.xAxes) {
      let xAxesVal = lerp(
        parseFloat(el.dataset.xAxesMin),
        parseFloat(el.dataset.xAxesMax),
        pcX
      );
      if (autoplayAnimation) {
        xAxesVal = quantize(xAxesVal, parseFloat(el.dataset.quantize || "1"));
      }
      el.style.setProperty(`--${el.dataset.xAxes}`, `${xAxesVal}`);
      el.style.setProperty(
        `--${el.dataset.xAxes}-text`,
        `"${Math.round(xAxesVal)}"`
      );

      el.style.setProperty(
        `--x-variable-value-text`,
        `"${Math.round(xAxesVal)}"`
      );
    }

    if (el.dataset.yAxes) {
      let yAxesVal = lerp(
        parseFloat(el.dataset.yAxesMin),
        parseFloat(el.dataset.yAxesMax),
        pcY
      );
      if (autoplayAnimation) {
        yAxesVal = quantize(yAxesVal, parseFloat(el.dataset.quantize || "1"));
      }
      el.style.setProperty(`--${el.dataset.yAxes}`, `${yAxesVal}`);
      el.style.setProperty(
        `--${el.dataset.yAxes}-text`,
        `"${Math.round(yAxesVal)}"`
      );
      el.style.setProperty(
        `--y-variable-value-text`,
        `"${Math.round(yAxesVal)}"`
      );
    }
  };

  const onMouseMove = ([clientX, clientY], [pcX, pcY]) => {
    setTypeVariationSettings(pcX, pcY);
  };

  const onFirstDeviceMotion = () => {};

  const onDeviceOrientation = ([gamma, beta], [pcX, pcY]) => {
    setTypeVariationSettings(pcX, pcY);
  };

  let orientationStuff = !autoplayAnimation
    ? useOrientationOrMousePosition(
        onMouseMove,
        onDeviceOrientation,
        onFirstDeviceMotion as any,
        el.firstElementChild
      )
    : null;

  let animation = autoplayAnimation
    ? anime({
        targets,
        x: [0, 1],
        y: [0, 1],
        direction: "alternate",
        loop: true,
        duration: 3000,
        easing: "easeInOutSine",
        autoplay: false,
        update: () => {
          setTypeVariationSettings(targets.x, targets.y);
        },
      })
    : null;

  const pollScroll = (loop = true) => {
    const st = getScrollTop();
    pc = clamp((st - min) / (max - min), 0, 1);

    blocks.forEach((block) => {
      const { offsetXPc, offsetYPc, offsetZPx, rotateXDeg, rotateYDeg } =
        block.dataset;

      let thisPc = clamp((st - min) / (max - min), 0, 1);
      thisPc = convertToRange(
        pc,
        [0, 1 - parseFloat(block.dataset.tOffset || "0")],
        [0, 1]
      );
      const oneMinusPc = 1 - easeOutCubic(thisPc, 0, 1, 1);

      block.style.setProperty(
        "--translate-x",
        `${oneMinusPc * block.clientWidth * parseFloat(offsetXPc!)}px`
      );
      block.style.setProperty(
        "--translate-y",
        `${oneMinusPc * block.clientHeight * parseFloat(offsetYPc!)}px`
      );
      block.style.setProperty(
        "--translate-z",
        `${oneMinusPc * parseFloat(offsetZPx!) * -1}px`
      );
      block.style.setProperty(
        "--rotate-x",
        `${oneMinusPc * parseFloat(rotateXDeg!)}deg`
      );
      block.style.setProperty(
        "--rotate-y",
        `${oneMinusPc * parseFloat(rotateYDeg!)}deg`
      );
    });

    if (loop) {
      raf = requestAnimationFrame(() => pollScroll());
    }
  };

  const setMinMax = (rect) => {
    const st = getScrollTop();
    min = rect.top + st - window.innerHeight;
    max = rect.bottom + st - window.innerHeight;
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      setBlockSizes();
      setMinMax(entries[0].boundingClientRect);
      resizeObserver.observe(document.body);
      orientationStuff?.enable();
      animation?.play();
      raf = requestAnimationFrame(() => pollScroll());
    } else {
      resizeObserver.unobserve(document.body);
      orientationStuff?.disable();
      animation?.pause();
      cancelAnimationFrame(raf);
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    setMinMax(el.getBoundingClientRect());
    setBlockSizes();
  });

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el);
  requestAnimationFrame(setBlockSizes);
};

export default BuildingBlocks;
