import { getScrollTop, lerp } from "../util/lib";
import { clamp } from "lodash";
import { easeOutCubic } from "js-easing-functions";

const Aaa = (el) => {
  let raf;
  let min, max;

  const flexBasisEls = [
    ...el.querySelectorAll("[data-component*='aaa__flex-basis']"),
  ];
  const aEls = [...el.querySelectorAll("[data-component*='aaa__a']")];
  const textEls = [...el.querySelectorAll("[data-component*='aaa__text']")];

  const pollScroll = () => {
    const st = getScrollTop();
    let scrollPc = clamp((st - min) / (max - min), 0, 1);
    scrollPc = easeOutCubic(scrollPc, 0, 1, 1);

    flexBasisEls.forEach((el) => {
      const val = lerp(
        parseFloat(el.dataset.flexBasisMin),
        parseFloat(el.dataset.flexBasisMax),
        scrollPc
      );

      el.style.setProperty("--flex-basis", `${val.toFixed(4) * 100}%`);
    });

    aEls.forEach((el) => {
      const val = lerp(
        parseFloat(el.dataset.heightVwMin),
        parseFloat(el.dataset.heightVwMax),
        scrollPc
      );

      const fakeDisplayVal = lerp(
        parseFloat(el.dataset.heightVwMin),
        el.dataset.heightVwMaxDisplay, // this is the value used to calculate the font size display text. It's how we can have a consistent value with the three but have actual different sizes to fake the differences between the three opsz
        scrollPc
      );

      el.style.setProperty("--height", `${val.toFixed(4)}vw`);
      el.style.setProperty(
        "--height-text",
        `" ${Math.round((fakeDisplayVal / 100) * window.innerWidth)}px"`
      );

      // set opsz for mobile
      if (el.dataset.opszMin && el.dataset.opszMax) {
        const val = lerp(
          parseFloat(el.dataset.opszMin),
          parseFloat(el.dataset.opszMax),
          scrollPc
        );
        el.style.setProperty("--font-opsz", `${val.toFixed(4)}`);
        el.style.setProperty("--font-opsz-text", `"${Math.round(val)}"`);
      }
    });

    textEls.forEach((el) => {
      const val = lerp(
        parseFloat(el.dataset.fontSizeMin),
        parseFloat(el.dataset.fontSizeMax),
        scrollPc
      );

      el.style.setProperty("--font-size", `${val.toFixed(4)}px`);
      el.style.setProperty("--font-size-text", `" ${Math.round(val)}px"`);

      // set opsz for mobile
      if (el.dataset.opszMin && el.dataset.opszMax) {
        const val = lerp(
          parseFloat(el.dataset.opszMin),
          parseFloat(el.dataset.opszMax),
          scrollPc
        );
        el.style.setProperty("--font-opsz", `${val.toFixed(4)}`);
        el.style.setProperty("--font-opsz-text", `"${Math.round(val)}"`);
      }
    });

    raf = requestAnimationFrame(pollScroll);
  };

  const setMinMax = () => {
    const rect = el.getBoundingClientRect();
    const st = getScrollTop();
    min = rect.top + st;
    max = min + rect.height - window.innerHeight;
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      setMinMax();
      resizeObserver.observe(document.body);
      raf = requestAnimationFrame(pollScroll);
    } else {
      resizeObserver.unobserve(document.body);
      cancelAnimationFrame(raf);
    }
  };
  const resizeObserver = new ResizeObserver(() => {
    setMinMax();
  });

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el.parentElement);

  setMinMax();
};
export default Aaa;
