import lottie from "lottie-web";
import * as animationSrcs from "url:../../animations/*";
import { getScrollTop } from "../util/lib";
import { clamp, over } from "lodash";
import { easeOutSine } from "js-easing-functions";

const SectionDoor = (el) => {
  const doorEls = [
    ...el.querySelectorAll("[data-component*='section-door__player']"),
  ];
  const scrollLen = parseInt(el.dataset.scrollLengthPx);
  let raf;
  let min, max;
  const mirrors = [];
  let lastScrollPc = -1;

  const player = lottie.loadAnimation({
    container: doorEls[0],
    renderer: "canvas",
    loop: false,
    autoplay: false,
    path: animationSrcs[el.dataset.src],
  });

  doorEls.slice(1).forEach((doorEl) => {
    const canvas = document.createElement("canvas");
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    const ctx = canvas.getContext("2d");
    doorEl.appendChild(canvas);
    mirrors.push(ctx);
  });

  const pollScroll = () => {
    const st = getScrollTop();
    let scrollPc = clamp((st - min) / (max - min), 0, 1);
    scrollPc = easeOutSine(scrollPc, 0, 1, 1);
    if (scrollPc !== lastScrollPc) {
      player.goToAndStop(Math.round(scrollPc * player.totalFrames), true);
      mirrors.forEach((ctx) => {
        ctx.canvas.width = player.container.width;
        ctx.canvas.height = player.container.height;
        ctx.drawImage(player.container, 0, 0);
      });
      // requestAnimationFrame(() => {
      // });
    }

    lastScrollPc = scrollPc;
    raf = requestAnimationFrame(pollScroll);
  };

  const setMinMax = () => {
    const parentRect = el.parentElement.getBoundingClientRect();
    const childRect = el.firstElementChild.getBoundingClientRect();
    const st = getScrollTop();
    const overflow = (childRect.height - window.innerHeight) / 2;
    el.style.marginBottom = `${scrollLen - overflow}px`;
    el.style.top = `-${overflow}px`;
    min = parentRect.top + st + overflow;
    max = min + scrollLen;
  };

  const onInViewChange = (entries) => {
    const styles = window.getComputedStyle(el);
    const display = styles.getPropertyValue("display");
    if (display === "none") return;

    if (entries[0].isIntersecting) {
      setMinMax();
      resizeObserver.observe(document.body);
      raf = requestAnimationFrame(pollScroll);
    } else {
      resizeObserver.unobserve(document.body);
      cancelAnimationFrame(raf);
    }
  };
  const resizeObserver = new ResizeObserver(() => {
    setMinMax();
  });

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el.parentElement);

  setMinMax();
};
export default SectionDoor;
