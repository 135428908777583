import { clamp, debounce } from "lodash";
import { getScrollTop } from "../util/lib";
import anime from "animejs";

const Nav = (el) => {
  const innerEl = el.firstElementChild;
  const scrollToTopEL = el.querySelector(
    "[data-component='nav__scroll-to-top']"
  );
  let st = getScrollTop();
  let previousSt = getScrollTop();
  let height = el.clientHeight;
  let raf;

  const targets = {
    value: 0,
  };

  const pollScroll = () => {
    previousSt = st;
    st = getScrollTop();
    const delta = previousSt - st;
    targets.value += delta;
    targets.value = clamp(targets.value, -height, 0);

    innerEl.style.setProperty(`--top`, `${targets.value}px`);

    raf = requestAnimationFrame(pollScroll);
  };

  const onResize = debounce(() => {
    height = el.clientHeight;
    innerEl.style.setProperty(`--top`, `${targets.value}px`);

    raf = requestAnimationFrame(pollScroll);
  }, 99);

  el.addEventListener("pointerenter", () => {
    anime({
      targets,
      value: 0,
      duration: 500,
      autoplay: true,
      ease: "easeInOutSine",
    });
  });

  scrollToTopEL.addEventListener("click", () => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  });

  window.addEventListener("resize", onResize);
  onResize();
};

export default Nav;
