import textFit from "textfit";
const CONFIG = {
  alignVert: false, // if true, textFit will align vertically using css tables
  alignHoriz: false, // if true, textFit will set text-align: center
  multiLine: true, // if true, textFit will not set white-space: no-wrap
  detectMultiLine: true, // disable to turn off automatic multi-line sensing
  minFontSize: 6,
  maxFontSize: 900,
  reProcess: true, // if true, textFit will re-process already-fit nodes. Set to 'false' for better performance
  widthOnly: false, // if true, textFit will fit text to element width, regardless of text height
  alignVertWithFlexbox: true, // if true, textFit will use flexbox for vertical alignment
};

// You can add more samples here!!
const SAMPLES = [
  {
    text: "Lorem Ipsum Dolor Sit Amet",
    opsz: 32,
    wght: 400,
    italic: false,
    oldstyle: false,
    allcaps: false,
    smallcaps: false,
  },
  {
    text: "Quid Pro Quo",
    opsz: 10,
    wght: 900,
    italic: true,
    oldstyle: false,
    allcaps: false,
    smallcaps: false,
  },
  {
    text: "Lupa Capitolina 296 ACN",
    opsz: 17,
    wght: 500,
    italic: false,
    oldstyle: false,
    allcaps: false,
    smallcaps: true,
  },
  {
    text: "Audacia Pro Muro Et Scuto Opus",
    opsz: 20,
    wght: 700,
    italic: false,
    oldstyle: false,
    allcaps: false,
    smallcaps: false,
  },
  {
    text: "Deus Ex Machina",
    opsz: 32,
    wght: 300,
    italic: false,
    oldstyle: false,
    allcaps: true,
    smallcaps: false,
  },
  {
    text: "Graecum Est; Non Legitur",
    opsz: 17,
    wght: 900,
    italic: false,
    oldstyle: false,
    allcaps: false,
    smallcaps: false,
  },
  {
    text: "Veritas Vitæ Magistra",
    opsz: 10,
    wght: 400,
    italic: false,
    oldstyle: false,
    allcaps: false,
    smallcaps: false,
  },
  {
    text: "Acta Est Fabula Plaudite!",
    opsz: 32,
    wght: 700,
    italic: false,
    oldstyle: false,
    allcaps: true,
    smallcaps: false,
  },
  {
    text: "Terminat Hora Diem; Terminat Auctor Opus.",
    opsz: 17,
    wght: 500,
    italic: true,
    oldstyle: false,
    allcaps: false,
    smallcaps: false,
  },
  {
    text: "An nescis, mi fili, quantilla prudentia mundus regatur?",
    opsz: 10,
    wght: 900,
    italic: false,
    oldstyle: false,
    allcaps: false,
    smallcaps: false,
  },
  {
    text: "De Omnibus Dubitandum",
    opsz: 17,
    wght: 400,
    italic: false,
    oldstyle: false,
    allcaps: false,
    smallcaps: true,
  },
  {
    text: "I, Vitelli, dei Romani sono belli!",
    opsz: 32,
    wght: 900,
    italic: true,
    oldstyle: false,
    allcaps: false,
    smallcaps: false,
  },
  {
    text: "Pax Intrantibus; Salus Exeuntibus.",
    opsz: 10,
    wght: 300,
    italic: false,
    oldstyle: false,
    allcaps: true,
    smallcaps: false,
  },
  {
    text: "Alma Mater",
    opsz: 17,
    wght: 500,
    italic: false,
    oldstyle: false,
    allcaps: false,
    smallcaps: false,
  },
];

const Typetester = (el) => {
  let sampleIndex = 0;
  const newSampleEls = [
    ...el.querySelectorAll("[data-component='tt__new-sample-btn']"),
  ];
  const opszRangeEls = [...el.querySelectorAll("[data-component='tt__opsz']")];
  const wghtRangeEls = [...el.querySelectorAll("[data-component='tt__wght']")];
  const italButtonEls = [
    ...el.querySelectorAll(
      "[data-component='tt__settings-btn'][data-name='italic-font']"
    ),
  ];
  const oldstyleButtonEls = [
    ...el.querySelectorAll(
      "[data-component='tt__settings-btn'][data-name='ot-osf']"
    ),
  ];
  const smallCapsButtonEls = [
    ...el.querySelectorAll(
      "[data-component='tt__settings-btn'][data-name='small-caps']"
    ),
  ];
  const allCapsButtonEls = [
    ...el.querySelectorAll(
      "[data-component='tt__settings-btn'][data-name='all-caps']"
    ),
  ];
  let isItal = false,
    isOldstyle = false,
    isAllCaps = false,
    isSmallCaps = false;
  const sampleEl = el.querySelector("[data-component='tt__sample']");
  const sampleTextEl = el.querySelector("[data-component='tt__sample-text']");

  // params = true | false | null (leave as is)
  const updateSettings = (italic, oldstyle, allcaps, smallcaps, wght, opsz) => {
    if (italic !== null) {
      if (italic) {
        isItal = true;
        italButtonEls.forEach((buttonEl) => (buttonEl.dataset.active = "true"));
        sampleEl.classList.add("italic-font");
      } else {
        isItal = false;
        italButtonEls.forEach(
          (buttonEl) => (buttonEl.dataset.active = "false")
        );
        sampleEl.classList.remove("italic-font");
      }
    }

    if (oldstyle !== null) {
      if (oldstyle) {
        isOldstyle = true;
        oldstyleButtonEls.forEach(
          (buttonEl) => (buttonEl.dataset.active = "true")
        );
        sampleEl.classList.add("ot-osf");
      } else {
        isOldstyle = false;
        oldstyleButtonEls.forEach(
          (buttonEl) => (buttonEl.dataset.active = "false")
        );
        sampleEl.classList.remove("ot-osf");
      }
    }

    if (allcaps !== null) {
      isSmallCaps = false;
      smallCapsButtonEls.forEach(
        (buttonEl) => (buttonEl.dataset.active = "false")
      );
      sampleEl.classList.remove("small-caps");

      if (allcaps) {
        isAllCaps = true;
        allCapsButtonEls.forEach(
          (buttonEl) => (buttonEl.dataset.active = "true")
        );
        sampleEl.classList.add("all-caps");
      } else {
        isAllCaps = false;
        allCapsButtonEls.forEach(
          (buttonEl) => (buttonEl.dataset.active = "false")
        );
        sampleEl.classList.remove("all-caps");
      }
    }

    if (smallcaps !== null) {
      isAllCaps = false;
      allCapsButtonEls.forEach(
        (buttonEl) => (buttonEl.dataset.active = "false")
      );
      sampleEl.classList.remove("all-caps");

      if (smallcaps) {
        isSmallCaps = true;
        smallCapsButtonEls.forEach(
          (buttonEl) => (buttonEl.dataset.active = "true")
        );
        sampleEl.classList.add("small-caps");
      } else {
        isSmallCaps = false;
        smallCapsButtonEls.forEach(
          (buttonEl) => (buttonEl.dataset.active = "false")
        );
        sampleEl.classList.remove("small-caps");
      }
    }

    if (wght !== null) {
      sampleEl.style.setProperty("--font-wght", `${wght}`);
      el.style.setProperty(`--font-wght-text`, `"${Math.round(wght)}"`);
      wghtRangeEls.forEach((el) => (el.value = wght));
    }

    if (opsz !== null) {
      sampleEl.style.setProperty("--font-opsz", `${opsz}`);
      el.style.setProperty(`--font-opsz-text`, `"${Math.round(opsz)}"`);
      opszRangeEls.forEach((el) => (el.value = opsz));
    }

    refresh();
  };

  const refresh = () => {
    textFit(sampleTextEl, CONFIG);
  };

  const newSample = () => {
    sampleIndex = (sampleIndex + 1) % SAMPLES.length;
    setSample(SAMPLES[sampleIndex]);
  };

  const setSample = ({
    text,
    opsz,
    wght,
    italic,
    oldstyle,
    allcaps,
    smallcaps,
  }) => {
    sampleTextEl.innerHTML = text;
    updateSettings(italic, oldstyle, allcaps, smallcaps, wght, opsz);
  };

  // return key be banned
  sampleTextEl.addEventListener("keydown", (e) => {
    if (evt.keyCode === e) {
      evt.preventDefault();
    }
  });

  sampleTextEl.addEventListener("input", refresh);

  newSampleEls.forEach((el) => {
    el.addEventListener("click", newSample);
  });

  wghtRangeEls.forEach((el) => {
    el.addEventListener("input", (e) => {
      updateSettings(null, null, null, null, e.target.value, null);
    });
  });

  opszRangeEls.forEach((el) => {
    el.addEventListener("input", (e) => {
      updateSettings(null, null, null, null, null, e.target.value);
    });
  });

  italButtonEls.forEach((buttonEl) => {
    buttonEl.addEventListener("click", (e) => {
      updateSettings(!isItal, null, null, null, null, null);
    });
  });

  oldstyleButtonEls.forEach((buttonEl) => {
    buttonEl.addEventListener("click", (e) => {
      updateSettings(null, !isOldstyle, null, null, null, null);
    });
  });

  allCapsButtonEls.forEach((buttonEl) => {
    buttonEl.addEventListener("click", (e) => {
      updateSettings(null, null, !isAllCaps, null, null, null);
    });
  });

  smallCapsButtonEls.forEach((buttonEl) => {
    buttonEl.addEventListener("click", (e) => {
      updateSettings(null, null, null, !isSmallCaps, null, null);
    });
  });

  setSample(SAMPLES[sampleIndex]);

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      refresh();
      resizeObserver.observe(document.body);
    } else {
      resizeObserver.unobserve(document.body);
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    refresh();
  });

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el);
};

export default Typetester;
