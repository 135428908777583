import anime from "animejs";
import useOrientationOrMousePosition from "../util/useOrientationOrMousePosition";
import { lerp } from "../util/lib";

const easeInOutSineInverse = (output) => Math.acos(1 - 2 * output) / Math.PI;

const RangeEl = (rangeEl, parentEl) => {
  const autoplayAnimation = matchMedia("(pointer:coarse)").matches;

  const targets = {
    value: parseFloat(rangeEl.value),
  };
  const value = [
    parseFloat(rangeEl.getAttribute("min")),
    parseFloat(rangeEl.getAttribute("max")),
  ];
  const calculateSeekPercent = () =>
    easeInOutSineInverse((targets.value - value[0]) / (value[1] - value[0]));
  const startSeekPercent = calculateSeekPercent();

  const syncValues = (value) => {
    targets.value = parseFloat(value);
    rangeEl.value = value;
    parentEl.style.setProperty(rangeEl.dataset.variable, `${value}`);
    parentEl.style.setProperty(
      `${rangeEl.dataset.variable}-text`,
      `"${Math.round(value)}"`
    );
  };

  const onMouseMove = ([clientX, clientY], [pcX, pcY]) => {
    if (rangeEl.dataset.axis === "x") {
      syncValues(lerp(value[0], value[1], pcX));
    } else if (rangeEl.dataset.axis === "y") {
      syncValues(lerp(value[0], value[1], pcY));
    }
  };

  let orientationStuff = !autoplayAnimation
    ? useOrientationOrMousePosition(onMouseMove, null, null, parentEl)
    : null;

  let animation = autoplayAnimation
    ? anime({
        targets,
        value,
        direction: "alternate",
        loop: true,
        duration: 5000,
        easing: "easeInOutSine",
        update: () => {
          syncValues(targets.value);
        },
        autoplay: false,
      })
    : null;

  rangeEl.addEventListener("input", (e) => {
    syncValues(e.target.value);
  });

  rangeEl.addEventListener("pointerover", () => {
    orientationStuff?.disable();
    animation?.pause();
  });
  rangeEl.addEventListener("touchstart", () => {
    orientationStuff?.disable();
    animation?.pause();
  });
  rangeEl.addEventListener("pointerout", () => {
    orientationStuff?.enable();
    animation?.seek(animation.duration * calculateSeekPercent());
    requestAnimationFrame(() => {
      animation?.play();
    });
  });
  rangeEl.addEventListener("touchend", () => {
    orientationStuff?.enable();
    animation?.seek(animation.duration * calculateSeekPercent());
    requestAnimationFrame(() => {
      animation?.play();
    });
  });

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      const styles = window.getComputedStyle(rangeEl);
      const display = styles.getPropertyValue("display");
      if (display !== "none") {
        animation?.seek(startSeekPercent * animation.duration);
        requestAnimationFrame(() => {
          animation?.play();
        });
      }
      orientationStuff?.enable();
    } else {
      animation?.pause();
      orientationStuff?.disable();
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(parentEl);
};

const AxesSpecimen = (el) => {
  const rangeEls = el.querySelectorAll(
    "[data-component='axes-specimen__range']"
  );

  rangeEls.forEach((rangeEl) => {
    RangeEl(rangeEl, el);
  });
};
export default AxesSpecimen;
