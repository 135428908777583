const _temp0 = require("url:../../animations/GT-Pantheon_Animation_Spacing_Intra.json");
const _temp1 = require("url:../../animations/GT-Pantheon_animation-fig-1-PS-003.json");
const _temp2 = require("url:../../animations/GT-Pantheon_animation-fig-11-Italic-angles-Clino-002.json");
const _temp3 = require("url:../../animations/GT-Pantheon_animation-fig-12_Ratio.json");
const _temp4 = require("url:../../animations/GT-Pantheon_animation-fig-2.1-R-003.json");
const _temp5 = require("url:../../animations/GT-Pantheon_animation-fig-2.2-A-003.json");
const _temp6 = require("url:../../animations/GT-Pantheon_animation-fig-3-sac-002.json");
const _temp7 = require("url:../../animations/GT-Pantheon_animation-fig-4-stg-003.json");
const _temp8 = require("url:../../animations/GT-Pantheon_animation-fig-5-pzk-002.json");
const _temp9 = require("url:../../animations/GT-Pantheon_animation-fig-6-493_8-002.json");
const _temp10 = require("url:../../animations/GT-Pantheon_animation-fig-7-sea-003.json");
const _temp11 = require("url:../../animations/GT-Pantheon_animation-fig-8-ni-006.json");
const _temp12 = require("url:../../animations/GT-Pantheon_animation-fig-9-we-003.json");
const _temp13 = require("url:../../animations/GT_P_1Blau_002.json");
const _temp14 = require("url:../../animations/GT_P_1Blau_008_mobile.json");
const _temp15 = require("url:../../animations/GT_P_1Blau_reduziertHoch_004.json");
const _temp16 = require("url:../../animations/GT_P_1Blau_reduziert_003.json");
const _temp17 = require("url:../../animations/GT_P_1Blau_reduziert_005.json");
const _temp18 = require("url:../../animations/GT_P_1Blau_reduziert_006.json");
const _temp19 = require("url:../../animations/GT_P_1_002.json");
const _temp20 = require("url:../../animations/GT_P_2_002.json");
const _temp21 = require("url:../../animations/GT_P_2_008_mobile.json");
const _temp22 = require("url:../../animations/GT_P_3_002.json");
const _temp23 = require("url:../../animations/GT_P_3_008_mobile.json");
const _temp24 = require("url:../../animations/vibe-1-mobile.json");
const _temp25 = require("url:../../animations/vibe-1.json");
const _temp26 = require("url:../../animations/vibe-2-mobile.json");
const _temp27 = require("url:../../animations/vibe-2.json");
const _temp28 = require("url:../../animations/vibe-3-mobile.json");
const _temp29 = require("url:../../animations/vibe-3.json");
const _temp30 = require("url:../../animations/vibe-4-mobile.json");
const _temp31 = require("url:../../animations/vibe-4.json");
module.exports = {
  "GT-Pantheon_Animation_Spacing_Intra.json": _temp0,
  "GT-Pantheon_animation-fig-1-PS-003.json": _temp1,
  "GT-Pantheon_animation-fig-11-Italic-angles-Clino-002.json": _temp2,
  "GT-Pantheon_animation-fig-12_Ratio.json": _temp3,
  "GT-Pantheon_animation-fig-2.1-R-003.json": _temp4,
  "GT-Pantheon_animation-fig-2.2-A-003.json": _temp5,
  "GT-Pantheon_animation-fig-3-sac-002.json": _temp6,
  "GT-Pantheon_animation-fig-4-stg-003.json": _temp7,
  "GT-Pantheon_animation-fig-5-pzk-002.json": _temp8,
  "GT-Pantheon_animation-fig-6-493_8-002.json": _temp9,
  "GT-Pantheon_animation-fig-7-sea-003.json": _temp10,
  "GT-Pantheon_animation-fig-8-ni-006.json": _temp11,
  "GT-Pantheon_animation-fig-9-we-003.json": _temp12,
  "GT_P_1Blau_002.json": _temp13,
  "GT_P_1Blau_008_mobile.json": _temp14,
  "GT_P_1Blau_reduziertHoch_004.json": _temp15,
  "GT_P_1Blau_reduziert_003.json": _temp16,
  "GT_P_1Blau_reduziert_005.json": _temp17,
  "GT_P_1Blau_reduziert_006.json": _temp18,
  "GT_P_1_002.json": _temp19,
  "GT_P_2_002.json": _temp20,
  "GT_P_2_008_mobile.json": _temp21,
  "GT_P_3_002.json": _temp22,
  "GT_P_3_008_mobile.json": _temp23,
  "vibe-1-mobile.json": _temp24,
  "vibe-1.json": _temp25,
  "vibe-2-mobile.json": _temp26,
  "vibe-2.json": _temp27,
  "vibe-3-mobile.json": _temp28,
  "vibe-3.json": _temp29,
  "vibe-4-mobile.json": _temp30,
  "vibe-4.json": _temp31
}